class ContentHubNavigation {
    constructor() {
        this.initialized = false;
        this.dropDowns = [];
        this.offscreenNav = document.body;
    }

    /**
     * Close all open drop downs
     * @param exceptFor element to ignore (optional)
     * @private
     */
    _closeOpenDropDowns( exceptFor ) {
        this.dropDowns.forEach((el) => {
            if( el !== exceptFor ) {
                el.parentNode.classList.remove('open');
            }
        });
    }

    /**
     * Bind all events to toggle off-canvas drawers
     * @private
     */
    _bindOffCanvasDrawerEvents() {
        // Open nav and prevent body scroll
        document.querySelectorAll('.subnavbar-toggler').forEach((el) => {
            el.addEventListener('click', (event) => {
                document.body.classList.add('subnav-opening');
                this.offscreenNav.classList.add('show');
                setTimeout( () => {
                    document.body.classList.remove('subnav-opening');
                    document.body.classList.add('subnav-open');
                }, 250);
            });
        });

        // Close Nav and remove classes
        document.querySelectorAll('.js-menu-close').forEach((el) => {
            el.addEventListener('click', (event) => {
                this.offscreenNav.classList.remove('show');
                document.body.classList.remove('subnav-open');
            });
        });
    }

    /**
     * Bind all events to toggle dropdown elements
     * @private
     */
    _bindDropdownEvents() {
        // Dropdown toggle
        this.dropDowns.forEach((el) => {
            el.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                let et = event.target;
                if( et ) {
                    et.parentNode.classList.toggle('open');
                    this._closeOpenDropDowns(et);
                }
                return false;
            });
        });
    }

    /**
     * Bind all events to toggle accorion collapsable elements
     * @private
     */
    _bindCollapseEvents() {
        // Collapse toggle for accordions
        document.querySelectorAll('[data-toggle="collapse"]').forEach((el) => {
            el.addEventListener('click', (event) => {
                document.querySelectorAll('.js-collapse').forEach(me => { me.classList.toggle('show') });
                return false;
            });
        });
    }

    /**
     * Bind document level events
     * @private
     */
    _bindDocumentEvents() {
        // Close if open
        document.addEventListener('click', (event) => {
            this._closeOpenDropDowns();
            if( document.body.classList.contains('subnav-open') ) {
                if( !this.offscreenNav.contains(event.target) ) {
                    this.offscreenNav.classList.remove('show');
                    document.body.classList.remove('subnav-open');
                }
            }
        });
    }

    /**
     * Fired when the DOM is available
     */
    onDocumentReady() {
        if( !this.initialized ) {
            this.dropDowns = document.querySelectorAll('.subnavbar-collapse [data-toggle="dropdown"]');
            this.offscreenNav = document.querySelector('#contenHubOffscreenNav');

            setTimeout(()=> {
                // Hide opening animation
                document.getElementById('contenHubOffscreenNav').style.display = 'block';
            }, 500);
            this._bindOffCanvasDrawerEvents();
            this._bindDropdownEvents();
            this._bindCollapseEvents();
            this._bindDocumentEvents();
            this.initialized = true;
        }
    }
}

let contentHubNavigation = new ContentHubNavigation();
if (document.readyState !== 'loading'){
    contentHubNavigation.onDocumentReady();
} else {
    document.addEventListener('DOMContentLoaded', function() {
        contentHubNavigation.onDocumentReady();
    });
}

